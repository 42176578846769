import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DepartmentValues } from '../components/DepartmentForm';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";
import {Appearance, Button} from "../components/Button";
import {postToBackend} from "../libraries/librequestutils";

interface DepartmentsUpdateRouteProps {
  companyId: string;
}

const DepartmentsImportBotUsersRoute: React.FC<DepartmentsUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { departmentId } = useParams<'departmentId'>();
  const { t, i18n } = useTranslation();
  const uploadBotUsersInput: React.RefObject<any> = React.useRef(null);

  const [imported, setImported] = React.useState(false)
  const [importing, setImporting] = React.useState(false);
  const [importData, setImportDate] = React.useState({});

  function onExportClick() {
     window.open(`/template-import.xlsx`, '_blank');
  }

  async function handleFileInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = evt.target.files?.[0];
    if (!file) return;

    setImporting(true)
    const result = await postToBackend(
      `/${companyId}/bot_users/import`,
      {
        file: file,
        department_id: departmentId// || data?.company!.departments[0]?.id
      }
    )
    const response = await result.json();

    setImporting(false);
    setImportDate(response.data);
    setImported(true);
  }

  async function handleImputConfirm(id: string) {
    const result = await postToBackend(
      `/${companyId}/bot_users/confirm_import`,
      {
        id: id,
        department_id: departmentId
      }
    )

    await result

    navigate(`/${companyId}/bot_users?department=${departmentId}`)
  }

  function resetImport(){
    setImporting(false);
    setImportDate({});
    setImported(false);
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <Link to={`/${companyId}/bot_users?department=${departmentId}`} className="hover:text-gray-400">
              {t('departments')}
            </Link>

            <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>

            <h1 className="mb-0 text-gray-900 truncate">
              {t('import')}
            </h1>
          </div>
        </div>
      </header>
      <main className="mt-8">
        {imported ? (
          <>
            <div className='bg-white p-6 mb-4 shadow'>
              <h2 className="mt-4 font-medium text-gray-900">{t('import_file')}</h2>
              <p className="text-gray-500">{t('confirm_import')}</p>
              <div className="flex justify-end space-x-3">
                <Button onClick={() => {handleImputConfirm(importData.id)}}>
                  {t('save')}
                </Button>
                <Button appearance={Appearance.Outline} type="button" onClick={() => resetImport()}>
                  {t('cancel')}
                </Button>
              </div>
            </div>

            <div className='bg-white p-6'>
              <div className="overflow-auto border border-gray-200 rounded-lg relative">
                <table className="min-w-full">
                  <thead className="bg-gray-50">
                    <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      <th scope="col" className="pl-8 py-3">
                        existing
                      </th>
                      <th scope="col" className="px-8 py-3 text-right">
                        first_name
                      </th>
                      <th scope="col" className="px-8 py-3 text-right">
                        last_name
                      </th>
                      <th scope="col" className="px-8 py-3 text-right">
                        mobile
                      </th>
                      <th scope="col" className="px-8 py-3 text-left">
                        email
                      </th>
                      <th scope="col" className="pr-8 py-3 text-right">
                        bot_user_type
                      </th>
                      <th scope="col" className="pr-8 py-3 text-right">
                        import_errors
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                      {importData.bot_user_import_rows.map((x) =>
                        <tr className={x.import_errors ? 'text-red-600' : x.existing ? 'text-gray-500' : 'text-green-600'}>
                          <td className="px-8 py-3 text-sm" >
                            {x.existing ? (<span className="material-symbols-outlined">person</span>) : (<span className="material-symbols-outlined text-green-600">person_add</span>)}
                          </td>
                          <td className="px-8 py-3 text-sm" >
                            <span className={x.existing ? "line-through text-red-600": ""}>{x.previous_first_name} </span>
                            <div>{x.first_name}</div>
                          </td>
                          <td className="px-8 py-3 text-sm" >
                            <span className={x.existing ? "line-through text-red-600": ""}>{x.previous_last_name} </span>
                            <div>{x.last_name}</div>
                          </td>
                          <td className="px-8 py-3 text-sm" >
                            <span>{x.mobile}</span>
                          </td>
                          <td className="px-8 py-3 text-sm" >
                            <span className={x.existing ? "line-through text-red-600": ""}>{x.previous_email} </span>
                            <div>{x.email}</div>
                          </td>
                          <td className="px-8 py-3 text-sm " >
                            <span className={x.existing ? "line-through text-red-600": ""}>{x.previous_bot_user_type} </span>
                            <div>{x.bot_user_type}</div>
                          </td>
                          <td className="px-8 py-3 text-sm" >
                            <span className={"text-red-600"}>{x.import_errors}</span>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className='bg-white p-6 mb-4'>
            <div>
              <h2 className="mt-4 font-medium text-gray-900">{t('import_file')}</h2>
              <p className="text-gray-500">{t('import_new_bot_users')}</p>
              <input ref={uploadBotUsersInput} onClick={(event)=> event.target.value = null} onChange={handleFileInputChange} type="file" className="sr-only"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"/>
              {importing != true &&
                <Button onClick={() => {uploadBotUsersInput.current.click()}}>
                  Import
                </Button>
              }
              {importing &&
                <span>{t('importing')}</span>
              }
            </div>
            <hr className='mt-4'/>
            <div>
              <h2 className="mt-4 font-medium text-gray-900">{t('dont_have_file_ready')}</h2>
              <p className="text-gray-500">{t('download_example')}</p>
              <Button onClick={() => onExportClick()}>
                {t('download_template')}
              </Button>
            </div>
          </div>
        )}
      </main>
    </div>
  )
}

export default DepartmentsImportBotUsersRoute;
