import * as React from 'react'
import { useId } from '@reach/auto-id'
import {ErrorMessage} from "@hookform/error-message";
import {SubscriptionFormQuery_companies} from "../../__generated__/SubscriptionFormQuery";

export interface CategoryCheckboxList {
  name: string
  setValue: any
  getValues: any
  id?: string
  className?: string
  label?: string
  errors?: any
  children: any
  style?: any
  categories?: DepartmentEditVisualsFormQuery_categories[],
}

export const CategoriesCheckboxList = React.forwardRef<HTMLInputElement, CategoryCheckboxList>((props, ref) => {

  const { id: unusedId, setValue, getValues, className, style, label, name, errors, categories, onChange } = props

  console.log(props)
  let selectedCategoryIds = Array.from(getValues("categoryIds") || [])

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newId = event.currentTarget.value;

    if(!selectedCategoryIds.includes(newId)){
      selectedCategoryIds.push(newId);
    }else{
      selectedCategoryIds.splice(selectedCategoryIds.indexOf(newId), 1);  //deleting
    }

    setValue("categoryIds", selectedCategoryIds.length > 0 ? selectedCategoryIds : ['']);
    onChange(event)
  };

  return (
    <div ref={ref} className="" style={style}>
      <label className=" block text-sm font-medium text-gray-700" htmlFor={unusedId}>
        {label}
      </label>
      <div ref={ref} className="bg-white block w-full pl-3 pr-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border" style={style}>
        <div>
          {(categories || []).map(edge => (
            <div key={edge!.id}>
              <label className="flex w-full">
                <span className="flex-1">{edge!.name}</span>
                <input type="checkbox" checked={selectedCategoryIds.includes(edge!.id)} onChange={onChangeSelect} key={edge!.id} value={edge!.id}>
                </input>
              </label>
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage errors={errors} className="mt-2 text-sm text-red-600" name={name} as="p" />
    </div>
  )
})
